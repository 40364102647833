import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { IUstGeneral } from '../models/ust-general';

import { catchError } from 'rxjs/operators';
import { IUstTank } from '../models/ust-tank';
import { IUstFuelSample } from '../models/ust-fuel-sample';
import { IUstInspection } from '../models/ust-inspection';
import { IUstLust } from '../models/ust-lust';

@Injectable({
  providedIn: 'root'
})
export class UstFacilityService {

  env = environment;
  private facilityUrl = this.env.apiBaseUrl + 'ustfacilities';
  constructor(private http: HttpClient) { }

  getExportUrl(facilityId: string): string {
    return this.facilityUrl + '/' + facilityId + '/Export';
  }

  getGeneralInformationForFacility(facilityId: string): Observable<IUstGeneral> {
    return this.http.get<IUstGeneral>(this.facilityUrl + '/' + facilityId).pipe(
      catchError(this.handleError)
    );
  }

  getTanksForFacility(facilityId: string): Observable<IUstTank[]> {
    return this.http.get<IUstTank[]>(this.facilityUrl + '/' + facilityId + '/Tanks').pipe(
      catchError(this.handleError)
    );
  }

  getFuelSamplesForFacility(facilityId: string): Observable<IUstFuelSample[]> {
    return this.http.get<IUstFuelSample[]>(this.facilityUrl + '/' + facilityId + '/FuelSamples').pipe(
      catchError(this.handleError)
    );
  }

  getInspectionsForFacility(facilityId: string): Observable<IUstInspection[]> {
    return this.http.get<IUstInspection[]>(this.facilityUrl + '/' + facilityId + '/Inspections').pipe(
      catchError(this.handleError)
    );
  }

  getLustInformationForFacility(facilityId: string): Observable<IUstLust> {
    return this.http.get<IUstLust>(this.facilityUrl + '/' + facilityId + '/Lust').pipe(
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    // TODO WE NEED TO LOG THIS ERROR TO AN API END POINT TO LOG THE ERROR CENTRALLY.
    // WE'LL ALSO LOG IT TO THE CONSOLE
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client side or network error occurred.  Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body should help to determine what happened
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
