import { Component, OnInit, ViewChild } from '@angular/core';
import { TgMapComponent } from 'tg-map';
import { environment } from '../../environments/environment';
import { loadModules } from 'esri-loader';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.css']
})
export class FacilitiesComponent implements OnInit {
  env = environment;
  @ViewChild(TgMapComponent) tgMapView: TgMapComponent; // needed to reference the child map component

  // Set our map properties
  ndImageryUrl = this.env.basemapImageryUrl;
  geojsonUrl = this.env.apiBaseUrl + 'centralizedfacilities';
  printUrl = 'https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task';
  // printUrl = 'https://ndgishub.nd.gov/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task';
  mapSettings = { mapCenterLng: -100.38439017, mapCenterLat: 47.566866, basemap: 'streets', mapZoomLevel: 7 };
  widgetPosition = { basemapGalleryBtn: 'top-left', homeBtn: 'top-left', layerListBtn: 'top-right', printBtn: 'top-right' };
  appOptions = { printBtn: false, homeBtn: true };
  ndBase: any;
  layer_hydrography: any;
  layer_transportation: any;
  layer_boundaries: any;

  allFacilities: any;
  airQualityFacilities: any;
  solidWasteFacilities: any;
  UICFacilities: any;
  USTFacilities: any;
  PDESFacilities: any;
  facilityGroupLayer: any;

  projLayerList: any;
  localSource: any;
  constructor() { }

  ngOnInit() {
    const viewDetailAction = {
      title: 'View Details',
      id: 'viewDetail'
    };

    const jsonTemplate = {
      title: '<b>{facilityName}</b>',
      actionsMenuEnabled: false,
      outFields: ['*'],
      content: getPopupContent
    };

    function getPopupContent(target) {
      const attr = target.graphic.attributes;

      let aqp = attr.isRegByAirQuality == 1 ? "<img title='Air Quality Program' src='assets/images/air_quality_icon_blue.png' class='programImage'/>" : "<img title='Air Quality Program' src='assets/images/air_quality_icon.png' class='grayImage' />";
      let swlp = attr.isRegBySolidWaste == 1 ? "<img title='Solid Waste Landfill Program' src='assets/images/solid_waste_icon_blue.png' class='programImage'/>" : "<img title='Solid Waste Landfill Program' src='assets/images/solid_waste_icon.png' class='grayImage' />";
      let ust = attr.isRegByUST == 1 ? "<img title='Underground Storage Tank (UST)' src='assets/images/underground_storage_icon_blue.png' class='programImage'/>" : "<img title='Underground Storage Tank (UST)' src='assets/images/underground_storage_icon.png' class='grayImage' />";
      let uic = attr.isRegByUIC == 1 ? "<img title='Underground Injection Control (UIC)' src='assets/images/underground_injection_blue.png' class='programImage'/>" : "<img title='Underground Injection Control (UIC)' src='assets/images/underground_injection.png' class='grayImage' />";
      let pdes = attr.isRegByPDES == 1 ? "<img title='Pollution Discharge Elimination System (PDES)' src='assets/images/pollution_discharge_icon_blue.png' class='programImage'/>" : "<img title='Pollution Discharge Elimination System (PDES)' src='assets/images/pollution_discharge_icon.png' class='grayImage' />";

      const vdbtn = "<div id='detailButton' name='detailButton' title='View Details' onClick=javascript:window.open('./facility/"+attr.facilitySeq+"','_blank') class='detailButton'>View Details</div>";
      let content = attr.addrLine1 +" "+ attr.city+", "+ attr.state +" "+ attr.zip+"<br />"
        +"<div class='programIcons'>Program Areas: "+ aqp + swlp + ust + uic + pdes +"</div>";

      if (attr.isRegBySolidWaste == 1 ||
          attr.isRegByUST == 1 ||
          attr.isRegByUIC == 1 ||
          attr.isRegByPDES == 1) {
            content += vdbtn;
          }
      return content;
    }

    const jsonRenderer_orange = {
      type: 'simple',
      field: 'facilityId, city',
      symbol: {
        type: 'simple-marker',
        color: 'orange',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_red = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: 'red',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_blue = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: 'blue',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_lBlue = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: '#2fc8ff',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_green = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: 'green',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_gray = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: '#7d7d7d',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    const jsonRenderer_brown = {
      type: 'simple',
      field: 'facilityId',
      symbol: {
        type: 'simple-marker',
        color: '#73390a',
        size: '10px',
        outline: {
          color: 'white'
        }
      }
    };

    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/widgets/BasemapGallery/support/LocalBasemapsSource',
      'esri/layers/TileLayer',
      'esri/Basemap',
      'esri/layers/MapImageLayer',
      'esri/layers/support/LabelClass',
      'esri/layers/GeoJSONLayer',
      'esri/layers/GroupLayer',
      'esri/support/actions/ActionButton'
    ]).then(([EsriMap, EsriMapView, LocalBasemapsSource, TileLayer, Basemap, EsriMapImageLayer,
                LabelClass, EsriGeoJSONLayer, GroupLayer, ActionButton]) => {
      this.ndBase = new TileLayer({
        url: 'https://ndgishub.nd.gov/arcgis/rest/services/Basemap_General/MapServer',
        id: 'ndBasemap',
        title: 'ND Custom Map',
        visible: false,
        opacity: 0.9,
        listMode: 'hide-children'
       });

      this.layer_hydrography = new EsriMapImageLayer({
        url: 'https://ndgishub.nd.gov/arcgis/rest/services/Features/Hydrography/MapServer',
        id: 'hydrography',
        title: 'Rivers and Lakes',
        visible: false,
        listMode: 'hide-children',
        sublayers: [
          {
            id: 5,
            visible: true,
            title: 'Generalized Lakes and Ponds'
          },
          {
            id: 4,
            visible: true,
            title: 'Generalized Rivers and Streams'
          },
          {
            id: 3,
            visible: true,
            title: 'Detailed Lakes and Ponds'
          },
          {
            id: 2,
            visible: true,
            title: 'Detailed Rivers and Streams'
          }
        ]
      });

      this.layer_transportation = new EsriMapImageLayer({
        url: 'https://ndgishub.nd.gov/ArcGIS/rest/services/All_Transportation/MapServer',
        id: 'transportation',
        title: 'Transportation',
        visible: true
      });

      this.layer_boundaries = new EsriMapImageLayer({
        url: 'https://ndgishub.nd.gov/arcgis/rest/services/All_GovtBoundaries/MapServer',
        id: 'boundaries',
        title: 'Boundaries',
        visible: true,
        sublayers: [
          {
            id: 20,
            visible: true,
            title: 'Counties'
          },
          {
            id: 6,
            visible: true,
            title: 'City Boundaries'
          },
          {
            id: 5,
            visible: true,
            title: 'Townships'
          },
          {
            id: 4,
            visible: true,
            title: 'Sections'
          }
        ]
      });
      const facilityLabelClass = new LabelClass({
        labelExpressionInfo: { expression: '$feature.facilityName' },
        minScale: 150000,
        symbol: {
          type: 'text',  // autocasts as new TextSymbol()
          color: '#634d0b',
          haloSize: 1,
          haloColor: 'white',
          xoffset: -5,
          yoffset: -6
        }
      });

      this.allFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'allFacilites',
        title: 'All Facilities',
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_orange
      });

      this.allFacilities.labelingInfo = [ facilityLabelClass ];

      this.airQualityFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'airQualityFacilities',
        definitionExpression: 'isRegByAirQuality = 1',   // isRegByAirQuality =true
        title: 'Facilities - Air Quality Program',
        visible: false,
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_lBlue
      });

      this.solidWasteFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'solidWasteFacilities',
        definitionExpression: 'isRegBySolidWaste = 1',
        title: 'Facilities - Solid Waste Landfill Program',
        visible: false,
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_brown
      });

      this.UICFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'UICFacilities',
        definitionExpression: 'isRegByUIC = 1',
        title: 'Facilities - Underground Injection Control',
        visible: false,
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_green
      });

      this.USTFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'USTFacilities',
        definitionExpression: 'isRegByUST = 1',
        title: 'Facilities - Underground Storage Tank',
        visible: false,
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_blue
      });

      this.PDESFacilities = new EsriGeoJSONLayer({
        url: this.geojsonUrl,
        id: 'PDESFacilities',
        definitionExpression: 'isRegByPDES = 1',
        title: 'Facilities - Pollution Discharge Elimination System',
        visible: false,
        copyright: 'ND DEQ',
        popupTemplate: jsonTemplate,
        renderer: jsonRenderer_gray
      });

      this.facilityGroupLayer = new GroupLayer({
        title: 'Facilities',
        visible: true,
        visibilityMode: 'independent',
        layers: [ this.allFacilities, this.PDESFacilities, this.UICFacilities,
          this.USTFacilities, this.solidWasteFacilities, this.airQualityFacilities] ,
        opacity: 1
      });
      this.projLayerList = [ this.ndBase, this.layer_hydrography, this.layer_transportation, this.layer_boundaries, this.facilityGroupLayer ];


      // ===============================
      // ========  BASEMAPS   ==========
      // ===============================
      const customImagery = new TileLayer({
        url: this.ndImageryUrl
       });
      const customImageryBasemap = new Basemap({
        baseLayers: [customImagery],
        thumbnailUrl: 'assets/images/ndimagery2017.png',
        title: 'ND Imagery',
        id: 'ND_Imagery'
      })

      const usaTopo = new TileLayer({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer'
      });

      const usaTopoBasemap = new Basemap({
        baseLayers: [usaTopo],
        thumbnailUrl: 'assets/images/usa_topo.png',
        title: 'USA Topo Maps',
        id: 'USA_Topo_Maps'
      });

      const usgsTopo = new TileLayer({
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer'
      });

      const usgsTopoBasemap = new Basemap({
        baseLayers: [usgsTopo],
        thumbnailUrl: 'assets/images/usgs_topo.png',
        title: 'USGS National Map',
        id: 'USGS_National_Map'
      });

      //list of basic basemaps
      const esriTopo = Basemap.fromId('topo');
      const esriStreets = Basemap.fromId('streets');
      const esriSatellite = Basemap.fromId('satellite');
      esriSatellite.title = 'Esri Imagery';
      const esriHybrid = Basemap.fromId('hybrid');
      esriHybrid.title = 'Esri Imagery with Labels';
      const esriDarkGray = Basemap.fromId('dark-gray');
      const esriGray = Basemap.fromId('gray');
      const esriTerrain = Basemap.fromId('terrain');
      const oceans = Basemap.fromId('oceans');
      const esriStreetsNightVector = Basemap.fromId('streets-night-vector');
      const ngo = Basemap.fromId('national-geographic');
      const osm = Basemap.fromId('osm');
      osm.title = 'OpenStreetMap';

      this.localSource = new LocalBasemapsSource({});
      this.localSource.basemaps = [customImageryBasemap,esriSatellite,esriHybrid,esriStreets,esriTopo,esriDarkGray,esriGray,esriTerrain,
        esriStreetsNightVector,oceans,osm,usaTopoBasemap,usgsTopoBasemap,ngo];

    }); //END OF then

  }

}
