import { UstFacilityService } from './../services/ust-facility.service';
import { IAqCorrespondence } from './../models/aq-correspondence';
import { IAqGeneral } from './../models/aq-general';
import { UicFacilityService } from './../services/uic-facility.service';
import { IPdesInspection } from './../models/pdes-inspection';
import { SwFacilityService } from './../services/sw-facility.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IFacility } from '../models/facility';
import { IAqPermit } from '../models/aq-permit';
import { IProgramArea } from '../models/program-area';

import { CentralFacilityService } from '../services/central-facility.service';
import { AqFacilityService } from '../services/aq-facility.service';
import { ISwInspection } from '../models/sw-inspection';
import { ISwGeneral } from '../models/sw-general';
import { ISwCorrespondence } from '../models/sw-correspondence';
import { IPdesGeneral } from '../models/pdes-general';
import { IPdesCorrespondence } from '../models/pdes-correspondence';

import { PdesFacilityService } from '../services/pdes-facility.service';
import { IPdesDmr } from '../models/pdes-dmr';
import { IUicGeneral } from '../models/uic-general';
import { IAqInspection } from '../models/aq-inspection';
import { IAqTesting } from '../models/aq-testing';
import { IUstGeneral } from '../models/ust-general';
import { IUstTank } from '../models/ust-tank';
import { IUstFuelSample } from '../models/ust-fuel-sample';
import { IUstInspection } from '../models/ust-inspection';
import { IUstLust } from '../models/ust-lust';


@Component({
  selector: 'app-facility-detail',
  templateUrl: './facility-detail.component.html',
  styleUrls: ['./facility-detail.component.css']
})
export class FacilityDetailComponent implements OnInit {
  ustExportUrl: string;
  uicExportUrl: string;
  aqExportUrl: string;
  swExportUrl: string;
  pdesExportUrl: string;

  baseFacility: IFacility;
  programAreas: IProgramArea[];
  firstPA: IProgramArea;
  aqPermits: IAqPermit[];
  aqInspections: IAqInspection[];
  aqCorrespondence: IAqCorrespondence[];
  aqTests: IAqTesting[];

  swInspections: ISwInspection[];
  swGeneral: ISwGeneral;
  swCorrespondence: ISwCorrespondence[];
  pdesGeneral: IPdesGeneral;
  pdesCorrespondence: IPdesCorrespondence[];
  pdesInspections: IPdesInspection[];
  pdesDmrs: IPdesDmr[];
  uicGeneral: IUicGeneral;
  aqGeneral: IAqGeneral;
  ustGeneral: IUstGeneral;
  ustTanks: IUstTank[];
  ustFuelSamples: IUstFuelSample[];
  ustInspections: IUstInspection[];
  ustLust: IUstLust;

  errorMessage: string;
  searchValue: string;
  selectedProgramArea: string;
  isAirQuality = false;
  isSolidWaste = false;
  isUIC = false;
  isUST = false;
  isPDES = false;

  private gridApi;
  private gridColumnApi;


  columnDefsAqPermits = [
    {headerName: 'Number', field: 'number', sortable: true, width: 100 },
    {headerName: 'Category', field: 'category', sortable: true, width: 100},
    {headerName: 'Permit Type', field: 'permitType', sortable: true, width: 150},
    {headerName: 'Application Status', field: 'applicationStatus', sortable: true, width: 150},
    {headerName: 'Permit Status', field: 'permitStatus', sortable: true, width: 100},
    {headerName: 'Permit Date', field: 'permitDate', sortable: true, width: 100, comparator: dateComparator },
    {headerName: 'Expiration Date', field: 'expirationDate', sortable: true, width: 120, comparator: dateComparator},
  ];

  columnDefsAqInspections = [
    {headerName: 'Date', field: 'inspectionDate', sortable: true, width: 100, comparator: dateComparator },
    {headerName: 'AIRS Type', field: 'airsType', sortable: true, width: 250},
    {headerName: 'AQPCP Type', field: 'aqpcpType', sortable: true, width: 170},
    {headerName: 'In Compliance', field: 'inCompliance', sortable: true, width: 130}
  ];

  columnDefsAqTesting = [
    {headerName: 'Date', field: 'testDate', sortable: true, width: 100, comparator: dateComparator },
    {headerName: 'Test Type', field: 'testType', sortable: true, width: 120},
    {headerName: 'Pass/Fail', field: 'testPassFail', sortable: true, width: 120},
    {headerName: 'Description', field: 'testComments', sortable: true, width: 400, autoHeight: true, cellClass: 'cell-wrap-text'}
  ];

  columnDefsAqCorrespondence = [
    {headerName: 'Date', field: 'correspondenceDate', sortable: true, width: 100, comparator: dateComparator },
    {headerName: 'Type', field: 'correspondenceType', sortable: true, width: 120},
    {headerName: 'Category', field: 'correspondenceCategory', sortable: true, width: 120},
    {headerName: 'Description', field: 'description', sortable: true, width: 400, autoHeight: true, cellClass: 'cell-wrap-text'}
  ];

  columnDefsSwInspections = [
    {headerName: 'Inspection Date', field: 'inspectionDate', sortable: true, width: 120, comparator: dateComparator },
    {headerName: 'Inspection Type', field: 'inspectionType', sortable: true, width: 150}
  ];

  columnDefsSwCorrespondence = [
    {headerName: 'Date', field: 'clDate', sortable: true, width: 120, comparator: dateComparator },
    {headerName: 'Correspondence Type', field: 'clType', sortable: true, width: 150},
    {headerName: 'Description', field: 'clDescription', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' }
  ];


  columnDefsPdesCorrespondence = [
    {headerName: 'Date', field: 'correspondenceDate', sortable: true, width: 120, comparator: dateComparator },
    {headerName: 'Correspondence Type', field: 'correspondenceType', sortable: true, width: 150},
    {headerName: 'Regarding', field: 'regarding', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' },
    {headerName: 'Description', field: 'description', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' }
  ];

  columnDefsPdesInspection = [
    {headerName: 'Inspection Date', field: 'inspectionDate', sortable: true, width: 120, comparator: dateComparator },
    {headerName: 'Inspection Type', field: 'inspectionType', sortable: true, width: 250},
    {headerName: 'Inspection Sent', field: 'inspectionReportSent', sortable: true, width: 170, comparator: dateComparator }
  ];

  columnDefsPdesDmr = [
    {headerName: 'Permit', field: 'permitNumber', sortable: true, width: 100 },
    {headerName: 'Discharge Start', field: 'dischargeStart', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Discharge End', field: 'dischargeEnd', sortable: true, width: 110, comparator: dateComparator },
    {headerName: '# Days', field: 'daysDischarged', sortable: true, width: 70 },
    {headerName: 'Parameter', field: 'parameter', sortable: true, width: 180 },
    {headerName: 'Conc Min', field: 'concentrationMin', sortable: true, width: 80 },
    {headerName: 'Conc Max', field: 'concentrationMax', sortable: true, width: 80 },
    {headerName: 'Conc Avg', field: 'concentrationAvg', sortable: true, width: 80 },
    {headerName: 'Units', field: 'units', sortable: true, width: 100 },
    {headerName: 'Load Max', field: 'loadMax', sortable: true, width: 80 },
    {headerName: 'Load Avg', field: 'loadAvg', sortable: true, width: 80 },
    {headerName: 'Load Units', field: 'loadUnits', sortable: true, width: 100 },
    {headerName: 'Discharge Loc', field: 'dischargeLocation', sortable: true, width: 130 },
    {headerName: 'No Data', field: 'noDataIndicator', sortable: true, width: 100 },
    {headerName: 'Num Exceed', field: 'numOfExceedances', sortable: true, width: 100 },
    {headerName: 'Sample Freq', field: 'sampleFrequency', sortable: true, width: 120 },
    {headerName: 'Sample Type', field: 'sampleType', sortable: true, width: 100 }
  ];

  columnDefsUicWellTypes = [
    {headerName: 'Well Code', field: 'wellCode', sortable: true, width: 90 },
    {headerName: 'Well Type', field: 'wellType', sortable: true, width: 250 }
  ];

  columnDefsUstTanks = [
    {headerName: 'Date Installed', field: 'dateInstalled', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Alternate Id', field: 'alternateId', sortable: true, width: 110 },
    {headerName: 'Tank Status', field: 'tankStatus', sortable: true, width: 220 },
    {headerName: 'Total Capacity', field: 'totalCapacity', sortable: true, width: 110 }
  ];

  columnDefsUstFuelSamples = [
    {headerName: 'Date Collected', field: 'dateCollected', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Date Received', field: 'dateReceived', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Violation', field: 'violation', sortable: true, width: 110 },
    {headerName: 'Comments', field: 'comments', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' }
  ];

  columnDefsUstInspections = [
    {headerName: 'Inspection Date', field: 'insDate', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Type', field: 'insType', sortable: true, width: 170 },
    {headerName: 'Inspector', field: 'inspectorName', sortable: true, width: 170 },
    {headerName: 'Comments', field: 'insComment', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' }
  ];

  columnDefsUstLustSummaries = [
    {headerName: 'Date', field: 'lusDate', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'LUST Id', field: 'lustId', sortable: true, width: 170 },
    {headerName: 'Alternate Id', field: 'alternateId', sortable: true, width: 170 },
    {headerName: 'Staff Lead', field: 'leadName', sortable: true, width: 170 }
  ];

  columnDefsUstStatuses = [
    {headerName: 'Date', field: 'issueDate', sortable: true, width: 110, comparator: dateComparator },
    {headerName: 'Lust Id', field: 'lustId', sortable: true, width: 110 },
    {headerName: 'Alternate Id', field: 'alternateId', sortable: true, width: 170 },
    {headerName: 'Status', field: 'status', sortable: true, width: 220 },
    {headerName: 'Lead Party', field: 'leadParty', sortable: true, width: 170 },
    {headerName: 'Comments', field: 'comments', sortable: true, width: 300, autoHeight: true, cellClass: 'cell-wrap-text' }
  ];
  columnDefsUstReleases = [
    {headerName: 'Lust Id', field: 'lustId', sortable: true, width: 170 },
    {headerName: 'Alternate Id', field: 'alternateId', sortable: true, width: 170 },
    {headerName: 'How Discovered', field: 'howDiscovered', sortable: true, width: 170 },
    {headerName: 'Date Discovered', field: 'dateDiscovered', sortable: true, width: 140, comparator: dateComparator }
  ];

  constructor(private centralFacilityService: CentralFacilityService,
              private aqFacilityService: AqFacilityService,
              private swFacilityService: SwFacilityService,
              private pdesFacilityService: PdesFacilityService,
              private uicFacilityService: UicFacilityService,
              private ustFacilityService: UstFacilityService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.centralFacilityService.getFacility(id).subscribe(
      fac => {
        console.log(fac);
        this.baseFacility = fac.centralFacilityInformation;
        this.programAreas = fac.programAreas;
        this.firstPA = this.programAreas[0];
        this.areaSelected(this.firstPA.divisionCode, this.firstPA.facilityId);
        console.log(this.baseFacility);
        console.log(this.programAreas);
      },
      error => this.errorMessage = error
    );

  }

  areaSelected(divisionCode, facilityId) {
    this.selectedProgramArea = facilityId;

    switch (divisionCode) {
      case 'AQ':
        this.fetchAirQualityDetails(facilityId);
        break;
      case 'UST':
        this.fetchUSTDetails(facilityId);
        break;
      case 'SW':
        this.fetchSolidWasteDetails(facilityId);
        break;
      case 'UIC':
        this.fetchUICDetails(facilityId);
        break;
      case 'NP':
        // console.log('division code NP, facilityId: ' + facilityId);
        this.fetchPDESDetails(facilityId);
        break;
    }
  }

  fetchAirQualityDetails(facilityId) {
    this.disableAllTabs();
    this.aqExportUrl = this.aqFacilityService.getExportUrl(facilityId);

    this.aqFacilityService.getGeneralInformationForFacility(facilityId).subscribe(
      genInfo => {
        this.aqGeneral = genInfo;
      },
      error => this.errorMessage = error
    );

    this.aqFacilityService.getPermitsForFacility(facilityId).subscribe(
      pmts => this.aqPermits = pmts,
      error => this.errorMessage = error
    );

    this.aqFacilityService.getInspectionsForFacility(facilityId).subscribe(
      insps => this.aqInspections = insps,
      error => this.errorMessage = error
    );

    this.aqFacilityService.getCorrespondenceForFacility(facilityId).subscribe(
      corr => this.aqCorrespondence = corr,
      error => this.errorMessage = error
    );

    this.aqFacilityService.getTestsForFacility(facilityId).subscribe(
      tests => this.aqTests = tests,
      error => this.errorMessage = error
    );

    this.isAirQuality = true;
    console.log('fetching aq data');
  }

  fetchSolidWasteDetails(facilityId) {
    this.disableAllTabs();
    this.swExportUrl = this.swFacilityService.getExportUrl(facilityId);

    this.swFacilityService.getGeneralInformationForFacility(facilityId).subscribe(
      genInfo => {
        this.swGeneral = genInfo;
      },
      error => this.errorMessage = error
    );

    this.swFacilityService.getCorrespondenceForFacility(facilityId).subscribe(
      corr => {
        this.swCorrespondence = corr;
      },
      error => this.errorMessage = error
    );

    this.swFacilityService.getInspectionsForFacility(facilityId).subscribe(
      insps => this.swInspections = insps,
      error => this.errorMessage = error
    );

    this.isSolidWaste = true;
  }

  fetchUICDetails(facilityId) {
    this.disableAllTabs();
    this.uicExportUrl = this.uicFacilityService.getExportUrl(facilityId);

    this.uicFacilityService.getGeneralInformationForFacility(facilityId).subscribe(
      genInfo => {
        this.uicGeneral = genInfo;
        console.log(this.uicGeneral);
      },
      error => this.errorMessage = error
    );

    this.isUIC = true;
    console.log('fetching uic data' + facilityId);
  }

  fetchUSTDetails(facilityId) {
    this.disableAllTabs();
    this.ustExportUrl = this.ustFacilityService.getExportUrl(facilityId);

    this.ustFacilityService.getGeneralInformationForFacility(facilityId).subscribe(
      genInfo => {
        this.ustGeneral = genInfo;
      },
      error => this.errorMessage = error
    );
    this.ustFacilityService.getTanksForFacility(facilityId).subscribe(
      tanks => {
        this.ustTanks = tanks;
      },
      error => this.errorMessage = error
    );
    this.ustFacilityService.getFuelSamplesForFacility(facilityId).subscribe(
      fuel => {
        this.ustFuelSamples = fuel;
      },
      error => this.errorMessage = error
    );
    this.ustFacilityService.getInspectionsForFacility(facilityId).subscribe(
      insps => {
        this.ustInspections = insps;
      },
      error => this.errorMessage = error
    );
    this.ustFacilityService.getLustInformationForFacility(facilityId).subscribe(
      lustInfo => {
        this.ustLust = lustInfo;
      },
      error => this.errorMessage = error
    );


    this.isUST = true;
    console.log('fetching ust data' + facilityId);
  }

  fetchPDESDetails(facilityId) {
    this.disableAllTabs();
    this.pdesExportUrl = this.pdesFacilityService.getExportUrl(facilityId);

    this.pdesFacilityService.getGeneralInformationForFacility(facilityId).subscribe(
      genInfo => {
        this.pdesGeneral = genInfo;
      },
      error => this.errorMessage = error
    );

    this.pdesFacilityService.getCorrespondenceForFacility(facilityId).subscribe(
      corr => {
        this.pdesCorrespondence = corr;
      },
      error => this.errorMessage = error
    );

    this.pdesFacilityService.getInspectionsForFacility(facilityId).subscribe(
      insps => {
        this.pdesInspections = insps;
      },
      error => this.errorMessage = error
    );

    this.pdesFacilityService.getDmrsForFacility(facilityId).subscribe(
      dmrs => {
        this.pdesDmrs = dmrs;
      },
      error => this.errorMessage = error
    );

    this.isPDES = true;
    console.log('fetching pdes data' + facilityId);
  }

  disableAllTabs() {
    this.isAirQuality = false;
    this.isSolidWaste = false;
    this.isUIC = false;
    this.isUST = false;
    this.isPDES = false;
  }

  formattedCityStateZip(city: string, state: string, zip: string, zip4: string) {
    let res = '';
    res = city + ' ' + state + ' ' + zip;
    if (zip4) {
      res = res + '-' + zip4;
    }
    return res;
  }

  programAreaImageSrc(divisionCode): string {
    let res = '';
    //assets/images/air_quality_icon.svg
    switch(divisionCode) {
      case 'AQ':
        res = 'assets/images/air_quality_icon.svg';
        break;
      case 'UST':
        res = 'assets/images/underground_storage_icon.svg';
        break;
      case 'SW':
        res = 'assets/images/solid_waste_icon.svg';
        break;
      case 'UIC':
        res = 'assets/images/underground_injection.svg';
        break;
      case 'NP':
        res = 'assets/images/pollution_discharge_icon.svg';
        break;
    }
    return res;

  }


}

function dateComparator(date1, date2) {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  if (date1Number === date2Number) {
    return 0;
  }
  if (date1Number < date2Number) {
    return -1;
  }
  return 1;
}

function monthToComparableNumber(date) {
  if (date === undefined || date === null || date.length < 8) {
    return null;
  }
  let hours = 0;
  let mins = 0;

  // The dates this routine support are in the format of 'mm/dd/yyyy' or 'mm/dd/yyyy hh:mi:ss'
  const datePieces = date.split(' ');
  const pieces = datePieces[0].split('/');

  if (datePieces.length > 1) {
    const timePieces = datePieces[1].split(':');
    const amOrPm = datePieces[2];
    hours = amOrPm === 'PM' ? timePieces[0] + 12 : timePieces[0];
    mins = timePieces[1];
  }

  const yearNumber = pieces[2];
  const monthNumber = pieces[0];
  const dayNumber = pieces[1];
  const result = new Date(yearNumber, monthNumber, dayNumber, hours, mins, 0, 0);
  return result;
}
