import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IAqPermit } from '../models/aq-permit';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { IAqGeneral } from '../models/aq-general';
import { IAqInspection } from '../models/aq-inspection';
import { IAqCorrespondence } from '../models/aq-correspondence';
import { IAqTesting } from '../models/aq-testing';

@Injectable({
  providedIn: 'root'
})
export class AqFacilityService {

  env = environment;
  private facilityUrl = this.env.apiBaseUrl + 'aqfacilities';
  constructor(private http: HttpClient) { }

  getExportUrl(facilityId: string): string {
    return this.facilityUrl + '/' + facilityId + '/Export';
  }

  getGeneralInformationForFacility(facilityId: string): Observable<IAqGeneral> {
    return this.http.get<IAqGeneral>(this.facilityUrl + '/' + facilityId).pipe(
      catchError(this.handleError)
    );
  }

  getPermitsForFacility(facilityId: string): Observable<IAqPermit[]> {
    return this.http.get<IAqPermit[]>(this.facilityUrl + '/' + facilityId + '/Permits').pipe(
      catchError(this.handleError)
    );
  }

  getInspectionsForFacility(facilityId: string): Observable<IAqInspection[]> {
    return this.http.get<IAqInspection[]>(this.facilityUrl + '/' + facilityId + '/Inspections').pipe(
      catchError(this.handleError)
    );
  }

  getCorrespondenceForFacility(facilityId: string): Observable<IAqCorrespondence[]> {
    return this.http.get<IAqCorrespondence[]>(this.facilityUrl + '/' + facilityId + '/Correspondence').pipe(
      catchError(this.handleError)
    );
  }

  getTestsForFacility(facilityId: string): Observable<IAqTesting[]> {
    return this.http.get<IAqTesting[]>(this.facilityUrl + '/' + facilityId + '/Testing').pipe(
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    // TODO WE NEED TO LOG THIS ERROR TO AN API END POINT TO LOG THE ERROR CENTRALLY.
    // WE'LL ALSO LOG IT TO THE CONSOLE
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client side or network error occurred.  Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body should help to determine what happened
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
