import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reusable-grid',
  templateUrl: './reusable-grid.component.html',
  styleUrls: ['./reusable-grid.component.css']
})
export class ReusableGridComponent implements OnInit {
  searchValue: string;
  private gridApi;
  private gridColumnApi;
  @Input() columnDefinitions;
  @Input() rowData;

  // columnDefs = [
  //   {headerName: 'Make', field: 'make', sortable: true },
  //   {headerName: 'Model', field: 'model', sortable: true},
  //   {headerName: 'Price', field: 'price', sortable: false}
  // ];
  // rowData = [
  //   {make: 'Toyota', model: 'Celica', price: 35000},
  //   {make: 'Ford', model: 'Mondeo', price: 32000},
  //   {make: 'Prosche', model: 'Boxster', price: 72000}
  // ];
  defaultColDef = {
    sortable: true,
    resizable: true
  };

  constructor() { }

  ngOnInit() {
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    console.log('grid is ready!');
  }

  quickSearch() {
    console.log('quick search clicked');
    this.gridApi.setQuickFilter(this.searchValue);
  }

}
