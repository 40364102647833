import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TgMapModule } from 'tg-map';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { FacilityDetailComponent } from './facility-detail/facility-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReusableGridComponent } from './reusable-grid/reusable-grid.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ApidocsComponent } from './apidocs/apidocs.component';

const appRoutes: Routes = [
  { path: 'facility/:id', component: FacilityDetailComponent },
  { path: 'facilities', component: FacilitiesComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'apidocs', component: ApidocsComponent },

  { path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },

  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  declarations: [
    AppComponent,
    FacilityDetailComponent,
    PageNotFoundComponent,
    FacilitiesComponent,
    DashboardComponent,
    ReusableGridComponent,
    ApidocsComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    TgMapModule,
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    AgGridModule.withComponents(null)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
