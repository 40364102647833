import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ISwInspection } from '../models/sw-inspection';
import { ISwGeneral } from '../models/sw-general';
import { ISwCorrespondence } from '../models/sw-correspondence';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SwFacilityService {

  env = environment;
  private facilityUrl = this.env.apiBaseUrl + 'swfacilities';
  constructor(private http: HttpClient) { }


  getExportUrl(facilityId: string): string {
    return this.facilityUrl + '/' + facilityId + '/Export';
  }

  getInspectionsForFacility(facilityId: string): Observable<ISwInspection[]> {
    return this.http.get<ISwInspection[]>(this.facilityUrl + '/' + facilityId + '/Inspections').pipe(
      catchError(this.handleError)
    );

  }

  getGeneralInformationForFacility(facilityId: string): Observable<ISwGeneral> {
    return this.http.get<ISwGeneral>(this.facilityUrl + '/' + facilityId).pipe(
      catchError(this.handleError)
    );
  }

  getCorrespondenceForFacility(facilityId: string): Observable<ISwCorrespondence[]> {
    return this.http.get<ISwCorrespondence[]>(this.facilityUrl + '/' + facilityId + '/Correspondence').pipe(
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    // TODO WE NEED TO LOG THIS ERROR TO AN API END POINT TO LOG THE ERROR CENTRALLY.
    // WE'LL ALSO LOG IT TO THE CONSOLE
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client side or network error occurred.  Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body should help to determine what happened
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
