import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { IPdesGeneral } from '../models/pdes-general';
import { IPdesCorrespondence } from '../models/pdes-correspondence';
import { IPdesInspection } from '../models/pdes-inspection';

import { catchError } from 'rxjs/operators';
import { IPdesDmr } from '../models/pdes-dmr';


@Injectable({
  providedIn: 'root'
})
export class PdesFacilityService {

  env = environment;
  private facilityUrl = this.env.apiBaseUrl + 'pdesfacilities';
  constructor(private http: HttpClient) { }

  getExportUrl(facilityId: string): string {
    return this.facilityUrl + '/' + facilityId + '/Export';
  }

  getGeneralInformationForFacility(facilityId: string): Observable<IPdesGeneral> {
    return this.http.get<IPdesGeneral>(this.facilityUrl + '/' + facilityId).pipe(
      catchError(this.handleError)
    );
  }

  getCorrespondenceForFacility(facilityId: string): Observable<IPdesCorrespondence[]> {
    return this.http.get<IPdesCorrespondence[]>(this.facilityUrl + '/' + facilityId + '/Correspondence').pipe(
      catchError(this.handleError)
    );
  }

  getInspectionsForFacility(facilityId: string): Observable<IPdesInspection[]> {
    return this.http.get<IPdesInspection[]>(this.facilityUrl + '/' + facilityId + '/Inspections').pipe(
      catchError(this.handleError)
    );
  }

  getDmrsForFacility(facilityId: string): Observable<IPdesDmr[]> {
    return this.http.get<IPdesDmr[]>(this.facilityUrl + '/' + facilityId + '/DischargeMonitoringReports').pipe(
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    // TODO WE NEED TO LOG THIS ERROR TO AN API END POINT TO LOG THE ERROR CENTRALLY.
    // WE'LL ALSO LOG IT TO THE CONSOLE
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client side or network error occurred.  Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body should help to determine what happened
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }


}
